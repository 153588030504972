import React, { useState } from 'react';
import { sendFiles } from '../../services/upload';

import {
  FileInput,
  Button,
  ValidationMessage,
  Spinner
} from '../../components';

import {
  Container,
  DivUpload,
  DivButton,
  DivMessage,
  Title,
  MessagesContainer
} from './styles';

type UploadError = Record<string, { line: number; [key: string]: unknown }[]>;

export const UploadFile: React.FC = () => {
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState({
    error: '',
    success: ''
  });
  const [successUpload, setSuccessUpload] = useState<boolean>(false);
  const [errorUpload, setErrorUpload] = useState<boolean>(false);

  const parseErrorMessage = (message: string): UploadError | string => {
    try {
      const parsed = JSON.parse(message);
      if (typeof parsed === 'object' && parsed !== null) {
        return parsed;
      }
    } catch {
      return message;
    }
    return message;
  };

  const formatErrorMessage = (message: string): string => {
    const error = parseErrorMessage(message);

    if (typeof error === 'string') {
      return error;
    }

    return Object.entries(error)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}:\n${value
            .map(
              (item) =>
                `• Line ${item.line}: ${Object.entries(item)
                  .filter(([field]) => field !== 'line')
                  .map(([_, val]) => ` ${val || 'Empty value'}`)
                  .join(', ')}`
            )
            .join('\n')}`;
        }
        return `${key}: ${String(value)}`;
      })
      .join('\n\n');
  };

  const uploadFile = async () => {
    setIsLoading(true);

    try {
      await sendFiles({ file });
      setIsLoading(false);
      setFileName('');
      setFile(undefined);
      setUploadMessage({
        ...uploadMessage,
        success: 'File successfully uploaded.'
      });
      setSuccessUpload(true);
    } catch (error: any) {
      setIsLoading(false);
      setFileName('');
      setFile(undefined);
      setUploadMessage((prev) => ({
        ...prev,
        error: formatErrorMessage(error.response.data.message)
      }));
      setErrorUpload(true);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent) => {
    setFileName('');
    setUploadMessage({ ...uploadMessage, error: '', success: '' });
    setSuccessUpload(false);
    setErrorUpload(false);
    setFile(undefined);

    const fileRead: any = event.target;
    const fileUploaded = fileRead?.files[0];
    setFile(fileUploaded);

    if (fileUploaded) {
      setFileName(fileUploaded.name);
    }
  };

  return (
    <Container>
      <Title>
        <h1>Upload CSV</h1>
      </Title>
      <DivUpload>
        <FileInput
          fileName={fileName}
          onChange={handleFileSelect}
          active={file !== undefined}
          setFile={setFile}
          setFileName={setFileName}
        />

        <DivButton>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Button active={!!file} disabled={!file} onClick={uploadFile}>
                Send file
              </Button>
            </>
          )}
        </DivButton>

        <MessagesContainer>
          {errorUpload && (
            <DivMessage>
              <ValidationMessage error>{uploadMessage.error}</ValidationMessage>
            </DivMessage>
          )}

          {successUpload && (
            <DivMessage>
              <ValidationMessage success>
                {uploadMessage.success}
              </ValidationMessage>
            </DivMessage>
          )}
        </MessagesContainer>
      </DivUpload>
    </Container>
  );
};
