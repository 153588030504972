import { Dispatch, SetStateAction } from 'react';
import { api } from './api';
import { history } from './history';

type Configuration = {
  automatic: boolean | undefined;
  beginPeriodDate: string;
  date: string;
  endPeriodDate: string;
  frequency: string;
  period: string;
  purchaseOrder: string;
  template: string;
};

export async function getCompanies(size?: number) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const hasSelectSize = size || 5;
  const response = await api.get(`/v1/customer?size=${hasSelectSize}`, {
    validateStatus(status) {
      const verifyStatus = status;
      if (verifyStatus !== 200) {
        setTimeout(() => {
          history.push('/');
          sessionStorage.removeItem('app-token');
        }, 10000);
      }
      return status < 300;
    }
  });
  return response.data;
}

export async function getFilterCompany(
  frequency?: string | '',
  search?: string | '',
  size?: number,
  page?: number,
  order?: string | '',
  orderBy?: string | '',
  setNoCompanyFound?: Dispatch<SetStateAction<boolean>>
) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const hasSelectSize = size || 5;
  const hasFilter = search?.length !== 0 ? `&filter=${search}` : '';
  const hasFrequency = frequency?.length !== 0 ? `&frequency=${frequency}` : '';

  const response = await api.get(
    `/v1/customer?page=${page}${hasFilter}${hasFrequency}&size=${hasSelectSize}&sort=${orderBy}%3A${order}`,
    {
      validateStatus(status) {
        const verifyStatus = status;
        if (verifyStatus !== 200) {
          setTimeout(() => {
            history.push('/');
            sessionStorage.removeItem('app-token');
          }, 10000);
        }
        return status < 300;
      }
    }
  );

  if (response.data.totalElements === 0 && setNoCompanyFound) {
    setNoCompanyFound(true);
  }
  return response.data;
}

export async function getCompanyById(companyId: number) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const response = await api.get(`/v1/customer/customer/${companyId}`);
  return response.data;
}

export async function getConfiguration(companyId: number) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const response = await api.get(
    `/v1/customer/{idConfig}?idConfig=${companyId}`
  );
  return response.data;
}

export async function saveCompanyConfig(
  configuration: Configuration,
  id: number
) {
  const token = sessionStorage.getItem('@fruit_people-token');
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const response = await api.put(`/v1/customer?idConfig=${id}`, configuration);
  return response.data;
}
